import { GetAddressesItem, GetAddressesItemTransformed, HereAddress } from 'app/services/hereMaps';

// Todo: Move to sa-common

export const transformGetAddressesItem = (getAddressesItem: GetAddressesItem): GetAddressesItemTransformed => {
  const label = getAddressLabel(getAddressesItem.address);

  return {
    ...getAddressesItem,
    transformed: {
      addressLabel: label,
    },
  };
};

export const getAddressLabel = (address: HereAddress) => {
  const parts: string[] = [];

  if (address.street && address.houseNumber) {
    parts.push(`${address.street} ${address.houseNumber}`);
  } else if (address.street) {
    parts.push(address.street);
  }

  if (address.postalCode && address.city) {
    parts.push(`${address.postalCode} ${address.city}`);
  } else if (address.city) {
    parts.push(address.city);
  }

  if (address.countryName) {
    parts.push(address.countryName);
  }

  return parts.join(', ');
};
