import {
  AUTOCOMPLETE_ENDPOINT,
  HERE_MAPS_API_KEY,
  LOOKUP_ENDPOINT,
  REVERSE_GEOCODE_ENDPOINT
  } from 'app/config';
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { transformGetAddressesItem } from 'common/utils/transformResponse';

// Todo: move types to sa-common

interface ReverseGeocodingArgs {
  longitude: number;
  latitude: number;
}

interface ReverseGeocodingResponse {
  items: ReverseGeocodingLocationItem[];
}

interface ReverseGeocodingLocationItem {
  title: string;
  id: string;
  resultType: string;
  houseNumberType: string;
  address: HereAddress;
  position: ReverseGeocodingCoordinates;
  access: ReverseGeocodingCoordinates[];
  distance: number;
  mapView: ReverseGeocodingMapView;
}

interface ReverseGeocodingCoordinates {
  lat: number;
  lng: number;
}

interface ReverseGeocodingMapView {
  west: number;
  south: number;
  east: number;
  north: number;
}

interface GetAddressesArgs {
  searchTerm: string;
}

interface GetAddressesHighlightRange {
  start: number;
  end: number;
}

interface GetAddressesHighlights {
  label?: GetAddressesHighlightRange[];
  city?: GetAddressesHighlightRange[];
  street?: GetAddressesHighlightRange[];
  houseNumber?: GetAddressesHighlightRange[];
}

interface GetAddressesHighlights {
  title?: GetAddressesHighlightRange[];
  address?: GetAddressesHighlights;
}

export interface HereAddress {
  label: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  state: string;
  countyCode?: string;
  county: string;
  city: string;
  district?: string;
  street: string;
  postalCode: string;
  houseNumber?: string;
}

export interface GetAddressesItem {
  title: string;
  id: string;
  language: string;
  resultType: string;
  houseNumberType?: string;
  address: HereAddress;
  highlights?: GetAddressesHighlights;
}

export interface GetAddressesItemTransformed extends GetAddressesItem {
  transformed: { addressLabel: string };
}

interface GetAddressesResponse {
  items: GetAddressesItem[];
}

interface LookupAddressArgs {
  id: string;
}

interface LookupAddressResponse {
  title: string;
  id: string;
  resultType: string;
  address: {
    label: string;
    countryCode: string;
    countryName: string;
    stateCode: string;
    state: string;
    countyCode: string;
    county: string;
    city: string;
    postalCode: string;
  };
  position: {
    lat: number;
    lng: number;
  };
  access: {
    lat: number;
    lng: number;
  }[];
  categories: {
    id: string;
    name: string;
    primary: boolean;
  }[];
  references: {
    supplier: {
      id: string;
    };
    id: string;
  }[];
  contacts: {
    phone?: { value: string }[];
    www?: { value: string }[];
    email?: { value: string }[];
  }[];
}

const baseQuery = fetchBaseQuery({
  baseUrl: '',
});

const baseQueryRetry = retry(baseQuery, {
  maxRetries: 0,
});

export const hereMapsApi = createApi({
  reducerPath: 'hereMapsApi',
  keepUnusedDataFor: 60 * 60 * 24 * 7,
  baseQuery: baseQueryRetry,
  endpoints: (build) => ({
    reverseGeocoding: build.query<ReverseGeocodingLocationItem[], ReverseGeocodingArgs>({
      query: ({ latitude, longitude }) => ({
        url: `${REVERSE_GEOCODE_ENDPOINT}/v1/revgeocode?at=${latitude},${longitude},250&limit=1&apiKey=${HERE_MAPS_API_KEY}`,
      }),
      transformResponse: (response: ReverseGeocodingResponse) => response.items,
    }),
    getAddresses: build.query<GetAddressesItemTransformed[], GetAddressesArgs>({
      query: ({ searchTerm }) => ({
        url: `${AUTOCOMPLETE_ENDPOINT}/v1/autocomplete?q=${encodeURIComponent(searchTerm)}&limit=4&apiKey=${HERE_MAPS_API_KEY}`,
      }),
      transformResponse: (response: GetAddressesResponse) =>
        response.items.map((item) => transformGetAddressesItem(item)),
    }),
    lookupAddress: build.query<LookupAddressResponse, LookupAddressArgs>({
      query: ({ id }) => ({
        url: `${LOOKUP_ENDPOINT}/v1/lookup?id=${id}&apiKey=${HERE_MAPS_API_KEY}`,
      }),
    }),
  }),
});

export const {
  useReverseGeocodingQuery,
  useLazyReverseGeocodingQuery,
  useLazyGetAddressesQuery,
  useLookupAddressQuery,
} = hereMapsApi;
